//@flow
import Raven from 'raven-js';
import { useEffect, useState } from 'react';
import {
  clearSessionAndRedirectToExpired,
  redirectToLogin,
  getUserAccountCache,
  getUserAccount,
  REJECT_REASON,
} from '@dt/session';
import { useSelector, useDispatch } from 'react-redux';
import { sessionCreate } from './redux/user_sessions/actions';
import type { UserAccount } from '@dt/session';
import type { UserSession } from '@dt/horizon-api';
import type { AccessControl } from '@dt/user-api/users';
import type { State } from './redux/store_state_type';

type SessionOptions = {
  +unauthenticatedRedirect?: boolean,
};

type SessionWorkflow = {
  +data: null | { user_account: UserAccount, user_session: UserSession },
  +loading: boolean,
  +error: null | Error,
};

/*
 * Retreives the current actor's authenticated session.
 *
 * There are primarily two workflows.
 * - Authenticated => Render page content.
 * - Unknown/Network Error => Render page error.
 * - Unauthenticated Redirect => Redirect the user to the login page if not authenticated.
 *                               This is on by default but can be turned off.
 *
 * @param SessionOptions.unauthenticatedRedirect - Redirect if the user is not authenticated.
 *                                                 On by default.
 *
 * @example
 *
 *   const Page = function Page() {
 *     const { data, loading, error } = useSession({ unauthenticatedRedirect: false });
 *     if (loading || !data) return <PageSkeleton />;
 *     if (error) return <PageError />;
 *     return <PageContent />
 *   };
 *
 */
export const useSession = (options?: SessionOptions): SessionWorkflow => {
  const unauthenticatedRedirect =
    typeof options?.unauthenticatedRedirect === 'boolean'
      ? options.unauthenticatedRedirect
      : true;

  const dispatch = useDispatch();

  const user_sessions = useSelector((s: State) => s.user_sessions);
  const [userAccount, setUserAccount] = useState(getUserAccountCache());
  const [error, setError] = useState<null | Error>(null);

  useEffect(
    () => {
      let isMounted = true;

      if (user_sessions.success) {
        return;
      }

      // Obtain user_account from sevenhell.
      getUserAccount()
        .then(result => {
          if (!isMounted) {
            return;
          }

          if (!result.no_session_reason) {
            setUserAccount(result);

            // Obtain user_session from horizon.
            dispatch(sessionCreate(result.sessionId));
          } else {
            if (!unauthenticatedRedirect) {
              // Provide error to client call site.
              setError(new Error(result.no_session_reason));
            } else {
              // Redirect when no user account was found.
              if (result.no_session_reason === REJECT_REASON.NO_SESSION_ID) {
                redirectToLogin();
              } else if (
                result.no_session_reason === REJECT_REASON.EXPIRED_SESSION_ID
              ) {
                clearSessionAndRedirectToExpired();
              } else {
                const error = new Error('Response Invalid');
                console.error(error);
                Raven.captureException(error, {
                  extra: {
                    msg: 'An error occurred fetching the user account.',
                  },
                });
                clearSessionAndRedirectToExpired();
              }
            }
          }
        })
        .catch(e => {
          Raven.captureException(e, {
            extra: { msg: 'An error occurred fetching the user account.' },
          });
          if (!unauthenticatedRedirect) {
            setError(e);
          } else {
            clearSessionAndRedirectToExpired();
          }
        });

      return () => {
        isMounted = false;
      };
    },
    // NOTE: Pulling from the store will force a deps change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unauthenticatedRedirect, dispatch],
  );

  // Error State
  if (error) {
    return { error, data: null, loading: false };
  }

  if (
    !userAccount ||
    !user_sessions.success ||
    !user_sessions.current_session
  ) {
    // Loading State
    return {
      error: null,
      data: null,
      loading: true,
    };
  } else {
    // Happy State
    return {
      data: {
        user_account: userAccount,
        user_session: user_sessions.current_session,
      },
      error: null,
      loading: false,
    };
  }
};

type AuthorizationWorkflow = {
  +isAuthorized: boolean,
};

/*
 * Compares the provided actor's authenticated session with provided access controls.
 *
 * If the user doesn't meet those access controls invalid is returned.
 * Otherwise the user has access.
 */
export const useAuthorization = (
  session: null | { user_account: UserAccount, user_session: UserSession },
  accessControls: AccessControl[],
): AuthorizationWorkflow => {
  let isAuthorized = true;
  if (session) {
    for (const accessControl of accessControls) {
      if (!session.user_account.currentUser[accessControl]) {
        isAuthorized = false;
        break;
      }
    }
  } else {
    isAuthorized = false;
  }

  return { isAuthorized };
};
